import styled, { css } from 'styled-components'
import { tokens, media } from '@tokens'

export const Heading = css`
  line-height: 1;
  font-family: ${tokens.get('type.fontFamilyHeading')};
  font-weight: ${tokens.get('type.fontWeight.regular')};
`

export const Label = css`
  color: ${tokens.brand('base')};
  font-family: ${tokens.get('type.fontFamily.lato')};
  font-weight: ${tokens.get('type.fontWeight.bold')};
  font-size: 0.75rem;
  line-height: 1;
  letter-spacing: ${tokens.get('type.letterSpacing.large')};
  text-transform: uppercase;
`

export const H1Sizes = css`
  font-size: 2rem;
  line-height: ${tokens.get('type.lineHeight.h1')};

  ${media.greaterThan('s')`
    font-size: 2.5rem;
  `}

  ${media.greaterThan('m')`
    font-size: 3.5rem;
  `};
`

export const H2Sizes = css`
  font-size: 1.5rem;
  line-height: ${tokens.get('type.lineHeight.h2')};

  ${media.greaterThan('s')`
    font-size: 2rem;
  `}

  ${media.greaterThan('m')`
    font-size: 3rem;
  `};
`

export const H3Sizes = css`
  font-size: 1.25rem;
  line-height: ${tokens.get('type.lineHeight.h3')};

  ${media.greaterThan('s')`
    font-size: 1.5rem;
  `}

  ${media.greaterThan('m')`
    font-size: 2rem;
  `};
`

export const H4Sizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.h4')};
`

export const H5Sizes = css`
  font-size: 0.875rem;
  line-height: ${tokens.get('type.lineHeight.h5')};
`

export const H6Sizes = css`
  font-size: 0.75rem;
  line-height: ${tokens.get('type.lineHeight.h6')};
`

export const Psizes = css`
  font-size: 1rem;
  line-height: ${tokens.get('type.lineHeight.body')};

  ${media.greaterThan('m')`
    font-size: 1.125rem;
  `};
`
export const PIntroSizes = css`
  font-size: 1.125rem;
  line-height: ${tokens.get('type.lineHeight.body')};

  ${media.greaterThan('s')`
    font-size: 1.25rem;
  `};

  ${media.greaterThan('m')`
    font-size: 1.375rem;
  `};
`

export const H1 = styled.h1`
  ${Heading};
  ${H1Sizes};
`
export const H2 = styled.h2`
  ${Heading};
  ${H2Sizes};
`

export const H3 = styled.h3`
  ${Heading};
  ${H3Sizes};
`

export const H4 = styled.h4`
  ${Heading};
  ${H4Sizes};
`

export const H5 = styled.h5`
  ${Heading};
  ${H5Sizes};
`

export const H6 = styled.h6`
  ${Heading};
  ${H6Sizes};
`

export const P = styled.p`
  ${Psizes}
`

export const PIntro = styled.p`
  ${PIntroSizes}
`

