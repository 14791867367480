import React from 'react'
import * as S from '@styles/components/Social'
import { ReactComponent as FacebookIcon } from '@icons/facebook.svg'
import { ReactComponent as InstagramIcon } from '@icons/instagram.svg'
import { ReactComponent as PinterestIcon } from '@icons/pinterest.svg'

const SocialIcon = ({ platform }) => {
  switch (platform) {
    case 'facebook':
      return <FacebookIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'pinterest':
      return <PinterestIcon />
    default:
      return false
  }
}

const Social = ({ socialData, className}) => {

  return (
    <S.Social className={className}>
      {socialData.platforms && Object.keys(socialData.platforms).map((platform) => (
        <li key={platform}>
          <a href={socialData.platforms[platform]} target="_blank">
            <SocialIcon platform={platform} />
          </a>
        </li>
      ))}
    </S.Social>
  )
}

export default Social
