import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import * as S from '@styles/components/BlogPosts'
import { Button } from '@styles/components/Button'
import Magic from '@components/Magic'

export const useBlogData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query BlogContent {
        markdownRemark(fileAbsolutePath: {regex: "/components\\/blog-posts/" }) {
          frontmatter {
            heading
            buttonUrl
            buttonText
            posts {
              heading
              date
              url
              image {
                childImageSharp {
                  fluid(maxWidth: 445) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

const PostTeaser = ({ heading, image, url, date }) => {
  return (
    <S.PostTeaser className="post-teaser">
      <Magic>
        <div className="post-image">
          <Image {...image.childImageSharp} alt="Blog Post" />
        </div>
        <div className="post-content">
          <div className="post-meta">
            {date}
          </div>
          <div className="post-title">
            {heading}
          </div>
          <a href={url} className="post-link">
            Read Article &gt;
          </a>
        </div>
      </Magic>
    </S.PostTeaser>
  )
}

const BlogPosts = () => {
  const {
    heading,
    buttonUrl,
    buttonText,
    posts,
  } = useBlogData()

  return (
    <S.BlogPosts>
      <Magic>
        <h3 className="label">{heading}</h3>
      </Magic>
      <div className="inner">
        {posts.map((post, index) => {
          return (
            <PostTeaser key={index} {...post} />
          )
        })}
      </div>
      <Magic>
        <div className="posts-link">
          <Button href={buttonUrl}>
            {buttonText}
          </Button>
        </div>
      </Magic>
    </S.BlogPosts>
  )
}

export default BlogPosts
