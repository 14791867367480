import React from 'react'
import Countdown from 'react-countdown-now';
import * as S from '@styles/components/Countdown'

const renderer = ({ days, hours, minutes, seconds, completed, ...props }) => {
  return (
    <div className="clock">
      <span className="time-value">
        {days}
        <span className="time-label">
          days
        </span>
      </span>
      <span className="time-value">
        {hours}
        <span className="time-label">
          hours
        </span>
      </span>
      <span className="time-value">
        {minutes}
        <span className="time-label">
          minutes
        </span>
      </span>
      <span className="time-value">
        {seconds}
        <span className="time-label">
          seconds
        </span>
      </span>
    </div>
  )
};

class CountdownComponent extends React.Component {
  constructor(props) {
    super(props)

    this.props = props;
  }

  render() {
    const {
      countdownLabel,
      countdownDate,
    } = this.props
    return (
      <S.Countdown>
        <span className="label" dangerouslySetInnerHTML={{ __html: countdownLabel }} />
        <Countdown
          date={countdownDate}
          renderer={renderer}
        />
      </S.Countdown>
    )
  }
}

export default CountdownComponent
