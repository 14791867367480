import React from 'react'
import Image from 'gatsby-image'
import * as S from '@styles/components/FloatingProducts'
import ScrollMagic from 'scrollmagic'
import 'scrollmagic.gsap'
import { TimelineMax } from 'gsap';

class FloatingProducts extends React.Component {

  constructor(props) {
    super(props)
    this.props = props;
    this.parallax = true;
    this.ref = React.createRef();
  }

  componentDidMount() {
    if (this.parallax) {
      this.controller = new ScrollMagic.Controller({
        globalSceneOptions: {
          offset: 0,
          duration: '100%',
        },
      })

      this.scenes = [
        new ScrollMagic.Scene()
          .setTween(
            this.ref.current.querySelector('.shadow-handler'),
            {
              opacity: 0,
              ease: Linear.easeNone,
              duration: '50%',
            }
          )
          .addTo(this.controller),
      ]
    }

    this.timeline = new TimelineMax({
      repeat: -1,
      yoyo: true,
    });

    this.ref.current.querySelectorAll('.product').forEach(product => {
      const randomNumber = Math.random();

      this.scenes.push(
        new ScrollMagic.Scene()
          .setTween(
            product.children[0],
            {
              yPercent: -1000,
              ease: Linear.easeNone,
              duration: `${randomNumber * -1}`,
            }
          )
          .addTo(this.controller)
      )
      this.timeline.fromTo(
        product,
        6,
        {
          rotation: -5,
          x: 0,
          y: '0%',
        },
        {
          rotation: 5,
          x: 0,
          y: '-40%',
          transformOrigin: '50% 50%',
          repeat: -1,
          yoyo: true,
          ease: Power1.easeInOut,
        },
        (randomNumber * -100)
      );
    })

    this.timeline.fromTo(
      this.ref.current.querySelectorAll('.shadow'),
      6,
      {
        autoAlpha: 1,
      },
      {
        autoAlpha: 0.7,
        repeat: -1,
        yoyo: true,
        ease: Power1.easeInOut,
      },
      "-=1000"
    );

    this.timeline.play();
  }

  componentWillUnmount() {
    this.timeline.kill();
  }

  render() {
    const {
      products,
      shadow
    } = this.props
    return (
      <S.FloatingProducts ref={this.ref}>
        <div className="product-container">
          {Object.keys(products).map(key => {
            const classes = `product ${key}`;
            return (
              <div key={key} className={classes}>
                <Image
                  {...products[key].childImageSharp}
                  className="product-image"
                  alt="Elate Rise Product"
                />
              </div>
            )
          })}
        </div>
        <div className="shadow-handler">
          <Image
            {...shadow.childImageSharp}
            className="shadow"
            alt="Shadow of floating products"
          />
        </div>
      </S.FloatingProducts>
    )
  }
}

export default FloatingProducts
