import styled from 'styled-components'
import { media, tokens, spacing, box } from '@tokens'

export const Newsletter = styled.section`
  ${box()}
  position: relative;
  z-index: ${tokens.z('aboveContent')};
  background-color: ${tokens.color('white')};

  .content {
    width: 100%;
    max-width: ${tokens.get('maxWidth.base')};
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    p {
      margin-bottom: ${spacing(4)};
    }
  }

  .logo-icon {
    margin-bottom: ${spacing(2)};
    width: ${spacing(8)};

    ${media.greaterThan('m')`
      margin-bottom: ${spacing(5)};
    `};
  }

  .heading {
    margin-bottom: ${spacing(4)};
    font-family: ${tokens.get('type.fontFamilyHeading')};
    font-weight: ${tokens.get('type.fontWeight.regular')};
    font-size: 2rem;
    line-height: 1.25;
  }

  form {
    padding: 0 !important;
    width: 100%;
    max-width: 23.75rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 3.125rem !important;

    * {
      height: 100% !important;
    }

    div {
      padding: 0 !important;
    }

    > div > div{
      position: relative;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
    }

    input[type="email"],
    button[type="button"] {
      display: block !important;
      padding: 0 !important;;
      margin: 0 !important;;
      background: none !important;
      border: none !important;
      border-bottom: 1px solid ${tokens.color('grey')} !important;
      font-family: ${tokens.get('type.fontFamilyBase')} !important;
    }

    input[type="email"] {
      border-radius: 0 !important;
      flex: 1 1 auto;

      &,
      &::placeholder {
        color: ${tokens.color('grey')};
        font-size: 0.875rem;
        line-height: 1;
      }

      + div {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
      }
    }

    button[type="button"] {
      display: flex !important;;
      flex-flow: row;
      justify-content: flex-end;
      align-items: center;
      width: 6.5rem;
      padding: 0 !important;
      font-family: ${tokens.get('type.fontFamily.lato')} !important;
      font-size: 0.75rem !important;
      line-height: 1 !important;
      letter-spacing: ${tokens.get('type.letterSpacing.large')} !important;
      text-transform: uppercase !important;
    }
  }

  .ql-editor {
    overflow: visible !important;

    p {
      margin-top: ${spacing(3)} !important;
      color: ${tokens.color('text')} !important;
      font-family: ${tokens.get('type.fontFamilyBase')} !important;
      font-weight: ${tokens.get('type.fontWeight.normal')} !important;

      &:first-child {
        margin-top: 0 !important;
        font-family: ${tokens.get('type.fontFamilyHeading')} !important;
        line-height: 1.25 !important;

        strong {
          font-weight: ${tokens.get('type.fontWeight.regular')} !important;
        }
      }
    }
  }

  .plant {
    position: absolute;
    z-index: ${tokens.z('base')};
  }

  .plant-left {
    bottom: 0;
    left: 0;
    width: 27.5%;

    ${media.lessThan('m')`
      transform: translateY(50%);
    `};
  }

  .plant-right {
    top: ${spacing(2)};;
    right: 0;
    width: 22.5%;
  }
`
