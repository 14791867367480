import React from 'react'
import ScrollMagic from 'scrollmagic'
import 'scrollmagic.gsap'
import { TweenMax } from 'gsap';

class Magic extends React.Component {

  constructor(props) {
    super(props)

    this.props           = props
    this.scenes          = []
    this.duration        = props.duration || 0.4
    this.triggerHook     = props.triggerHook || 0.95
    this.ref = React.createRef()
  }

  componentDidMount() {
    const {
      startStyles,
      endStyles
    } = this.props
    this.magicController = new ScrollMagic.Controller()
    this.startStyles = startStyles || {
      opacity: 0,
      x: 0,
      y: 50,
    }
    this.endStyles = endStyles || {
      opacity: 1,
      x: 0,
      y: 0,
      ease: Sine.easeOut,
    }

    const {
      ignoreWrapper
    } = this.props
    const {
      children
    } = this.ref.current

    this.targets = (
      ignoreWrapper
      ? [...children[0].children]
      : [...children]
    );

    this.controller = new ScrollMagic.Controller({
      globalSceneOptions: {
        offset: 0,
        duration: '100%',
      },
    })

    this.targets.forEach(target => {
      TweenMax.to(target, 0, this.startStyles)

      const scene = new ScrollMagic.Scene({
        triggerElement: target,
        triggerHook: this.triggerHook,
      })
        .setClassToggle(target, "is-loaded")
        .setTween(target, this.duration, this.endStyles)
        //.addIndicators() // Good for debugging
        .addTo(this.magicController)
      this.scenes.push(scene)
    })
  }

  render() {
    return (
      <>
        <span ref={this.ref}>
          {this.props.children}
        </span>
      </>
    )
  }
}

export default Magic
