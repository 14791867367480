import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import "video-react/dist/video-react.css"
import * as S from '@styles/components/VideoBanner'
import { Player, ControlBar } from 'video-react'
import { ReactComponent as PlayIcon } from '@icons/play.svg'
import { ReactComponent as Close } from '@icons/close.svg'
import { Overlay } from '@styles/components/Overlay'
import { TimelineMax } from 'gsap'
import disableScroll from 'disable-scroll'
import Magic from '@components/Magic'

export const useVideoBannerData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query VideoBannerContent {
        markdownRemark(fileAbsolutePath: {regex: "/components\\/video-banner/" }) {
          frontmatter {
            mutedVideo
            fullVideo
            buttonText
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

const FlyoutTimeline = ({ current: target }) => {
  return new TimelineMax({
    paused: true,
  }).fromTo(
    target,
    0.4,
    {
      autoAlpha: 0,
      y: '-60%',
      x: '-50%',
      scale: 0.8,
      transformOrigin: '50% 50%',
    },
    {
      autoAlpha: 1,
      y: '-50%',
      x: '-50%',
      scale: 1,
    }
  )
}

const VideoBanner = () => {
  const open = false
  const [isFlyout, setIsFlyout] = useState({ open })
  const [flyout, setFlyout]     = useState({ timeline: null})
  const flyoutRef        = useRef()
  const backgroundPlayer = useRef()
  const flyoutPlayer     = useRef()
  const {
    mutedVideo,
    fullVideo,
    buttonText,
  } = useVideoBannerData()

  useEffect(() => {
    setFlyout({
      timeline: FlyoutTimeline(flyoutRef)
    })
  }, [flyoutRef.current])

  useEffect(() => {
    if (!flyout.timeline) {
      return
    }

    if (isFlyout.open) {
      flyout.timeline.play()
      videoPause(backgroundPlayer)
      videoPlay(flyoutPlayer)
      disableScroll.on()
    } else {
      flyout.timeline.reverse()
      videoPlay(backgroundPlayer)
      videoPause(flyoutPlayer)
      disableScroll.off()
    }

    window.addEventListener('keydown', (e) => handleUserKeyPress(isFlyout.open, e));

    return () => {
      window.removeEventListener('keydown', (e) => handleUserKeyPress(isFlyout.open, e));
    };
  }, [isFlyout])

  const videoPlay = ({ current: player }) => {
    player.play()
  }

  const videoPause = ({ current: player }) => {
    player.pause()
  }

  // Update state
  const toggleFlyout = () => {
    setIsFlyout({
      open: (!isFlyout.open)
    })
  }

  const closeFlyout = () => {
    if (isFlyout.open) {
      toggleFlyout()
    }
  }

  const handleUserKeyPress = (isFlyoutOpen, { keyCode }) => {
    if (keyCode === 27 && isFlyoutOpen) {
      closeFlyout()
    }
  }

  return (
    <S.VideoBanner>
      <Player
        className="background-video"
        muted
        autoPlay
        playsInline
        loop
        ref={backgroundPlayer}
      >
        <source src={mutedVideo} />
        <ControlBar disableCompletely />
      </Player>
      <button
        className="video-play"
        type="button"
        onClick={toggleFlyout}
      >
        <Magic>
          <div className="button__inner">
            <PlayIcon className="play-icon" />
            {buttonText}
          </div>
        </Magic>
      </button>
      <S.Flyout open={isFlyout.open} ref={flyoutRef}>
        <Close className="flyout-close" onClick={closeFlyout} />
        <Player
          autoPlay={false}
          paused
          ref={flyoutPlayer}
        >
          <source src={fullVideo} />
          <ControlBar />
        </Player>
      </S.Flyout>
      <Overlay open={isFlyout.open} onClick={closeFlyout} />
    </S.VideoBanner>
  )
}
export default VideoBanner
