import styled from 'styled-components'
import { Label } from '@styles/Typography'
import { media, tokens, spacing, box } from '@tokens'

export const BlogPosts = styled.section`
  padding: ${spacing(5)} ${spacing(3)};
  background-color: ${tokens.color('white')};

  ${media.greaterThan('s')`
    ${box({
      spacing: 'vertical',
      multiplier: 0.75,
    })}
  `};

  .inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: ${spacing(3)};;
    grid-row-gap: 0;

    ${media.greaterThan('s')`
      grid-template-columns: repeat(2,1fr);
    `};

    ${media.greaterThan('m')`
      grid-template-columns: repeat(3,1fr);
    `};
  }

  .label {
    margin-bottom: ${spacing(4)};
    color: ${tokens.color('text')};
    text-align: center;

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(6)};
    `}
  }

  .posts-link {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    margin-top: ${spacing(3)};
    cursor: pointer;
  }
`

export const PostTeaser = styled.div`

  ${media.lessThan('s')`
    &:nth-child(2) {
      display: none;
    }
  `};

  ${media.lessThan('m')`
    &:last-child {
      display: none;
    }
  `};

  .post-content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: ${spacing(3)};
  }

  .post-meta,
  .post-title,
  .post-link {
    display: block;
  }

  .post-meta,
  .post-title {
    margin-bottom: ${spacing(2)};
  }

  .post-meta,
  .post-link {
    ${Label}
  }

  .post-title {
    font-size: 1.375rem;
    font-weight: ${ tokens.get('type.fontWeight.regular')};
    line-height: 1.45;
    text-align: center;
  }

  .post-link {
    &:hover {
      color: ${tokens.brand('darker')};
    }
  }
`