import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import SEO from "@components/Seo"
import Hero from '@components/Hero'
import Newsletter from '@components/Newsletter'
import Tiles from '@components/Tiles'
import VideoBanner from '@components/VideoBanner'
import BlogPosts from '@components/BlogPosts'
import Footer from '@components/Footer'


const LandingPageContent = ({ hasCountdown }) => {
  return (
    <>
      {hasCountdown === true &&
        <Newsletter />
      }
      <Tiles hasCountdown={hasCountdown} />
      <VideoBanner />
      <BlogPosts />
    </>
  )
}

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.isLanding  = props.pageContext.isLanding;
    const today = new Date();
    this.countdownDate = new Date(this.props.data.markdownRemark.frontmatter.countdownDate);
    this.hasCountdown = this.countdownDate.getTime() > today.getTime()
  }


  render() {
    const {
      title,
      description,
    } = this.props.data.markdownRemark.frontmatter

    return (
      <Layout>
        <SEO
          title={title}
          description={description}
        />
        <Hero
          isLanding={this.isLanding}
          countdownDate={this.countdownDate}
          hasCountdown={this.hasCountdown}
        />
        {this.isLanding === true &&
          <LandingPageContent hasCountdown={this.hasCountdown} />
        }
        <Footer />
      </Layout>
    )
  }
}

export default Page

export const pageQuery = graphql`
  query PageBySlugs($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
        countdownDate
      }
    }
  }
`
