import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { ReactComponent as ElateIcon } from '@images/elate-icon.svg'
import * as S from '@styles/components/Newsletter'
import Magic from '@components/Magic'

export const useNewsletterData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query NewsletterContent {
        markdownRemark(fileAbsolutePath: { regex: "/components/newsletter/" }) {
          frontmatter {
            heading
            klaviyoFormClass
            plantLeft {
              childImageSharp {
                fluid(maxHeight: 1355, maxWidth: 1682) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            plantRight {
              childImageSharp {
                fluid(maxHeight: 689, maxWidth: 1067) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          html
        }
      }
    `
  )
  return markdownRemark
}

class NewsletterScript extends React.Component {
  componentDidMount() {
    var s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = '//static.klaviyo.com/onsite/js/klaviyo.js?company_id=HUHZrN'
    var y = document.getElementsByTagName('script')
    var x = y[y.length - 1]
    x.parentNode.insertBefore(s, x)
  }

  render() {
    return null
  }
}

const Newsletter = () => {
  const {
    frontmatter: { heading, klaviyoFormClass, plantLeft, plantRight },
    html,
  } = useNewsletterData()

  return (
    <S.Newsletter>
      <div className="inner">
        <article className="content">
          <Magic>
            <ElateIcon className="logo-icon" alt="Elate Logo Icon" />
            <h1
              className="heading"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <div className={klaviyoFormClass} />
            <NewsletterScript></NewsletterScript>
          </Magic>
        </article>
      </div>
      <div className="plant plant-left">
        <Image {...plantLeft.childImageSharp} alt="Background Plant" />
      </div>
      <div className="plant plant-right">
        <Image {...plantRight.childImageSharp} alt="Background Plant" />
      </div>
    </S.Newsletter>
  )
}

export default Newsletter
