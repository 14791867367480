import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import * as S from '@styles/components/ThankYouContent'

export const useThankYouContentData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query ThankYouContentContent {
        markdownRemark(fileAbsolutePath: {regex: "/thank-you/" }) {
          html
        }
      }
    `
  )
  return markdownRemark
}

const ThankYouContent = () => {
  const {
    html
  } = useThankYouContentData();

  return (
    <S.ThankYouContent className="ThankYouContent">
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </S.ThankYouContent>
  )
}

export default ThankYouContent