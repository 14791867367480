import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { ReactComponent as Logo} from '@images/elate-logo-vertical-white.svg';
import * as S from '@styles/components/Footer'
import Social from '@components/Social'

export const useFooterData = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query FooterContent {
        markdownRemark(fileAbsolutePath: {regex: "/components\\/footer/" }) {
          frontmatter {
            navColumns {
              heading
              links {
                label
                url
              }
            }
            social {
              heading
              platforms {
                facebook
                pinterest
                instagram
              }
            }
            copyright {
              company
              credit {
                label
                url
              }
              links {
                label
                url
              }
            }
          }
        }
      }
    `
  )
  return markdownRemark.frontmatter
}

const Footer = () => {
  const year = new Date().getFullYear();
  const {
    navColumns  ,
    social: socialData,
    copyright,
  } = useFooterData();

  return (
    <S.Footer>
      <div className="inner">
        <a key="logo-link" className="logo-link" href="https://elatecosmetics.ca">
          <Logo />
        </a>

        {navColumns.map((column, columnIndex) => {
          return (
            <ul className="menu-list" key={`menu-list-${columnIndex}`}>
              <li key="list-heading" className="menu-list-item footer-heading">
                {column.heading}
              </li>
              {column.links.map((link, index) => {
                return (
                  <li key={`menu-list-item-${index}`} className="menu-list-item">
                    <a className="menu-list-link" href={link.url}>
                      {link.label}
                    </a>
                  </li>
                )
              })}
            </ul>
          )
        })}

        <div className="social">
          <span key="footer-heading" className="footer-heading">
            {socialData.heading}
          </span>
          <Social key='social-platforms' socialData={socialData} />
        </div>
      </div>
      <div className="copyright">
        COPYRIGHT &copy; {year}, ELATE COSMETICS
        {copyright.links.map((link, index) => {
          return (
            <a key={`copyright-link-${index}`} href={link.url}>
              {link.label}
            </a>
          )
        })}
        Website by
        {copyright.credit.map((link, index) => {
          const amp = (index > 0 ? '&amp;' : '');
          return (
            <span key={`span-${index}`}>
              <span dangerouslySetInnerHTML={{ __html: amp}} />
              <a href={link.url} target="_blank">
                {link.label}
              </a>
            </span>
          )
        })}
      </div>
    </S.Footer>
  )
}

export default Footer
