import { createGlobalStyle } from 'styled-components'
import { Heading, Label } from '@styles/Typography'
import tokens from '@tokens'

const Global = createGlobalStyle`
  /* RESET */
  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd, ol, ul,
  figure,
  hr,
  fieldset, legend {
    margin:  0;
    padding: 0;
  }

  li > {
    ol, ul {
      margin-bottom: 0;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  fieldset {
    border: 0;
    min-width: 0;
  }

  /* GLOBAL */
  html {
    box-sizing: border-box;
    color: ${tokens.color('text')};
    font-family: ${tokens.get('type.fontFamilyBase')};
    font-size: ${tokens.fontSize('p')};
    font-weight: ${tokens.get('type.fontWeight.normal')};
    line-height: ${tokens.get('type.lineHeight.body')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;

    * {
      outline-color: ${tokens.brand('base')};

      &, &::before, &::after {
        box-sizing: inherit;
      }
    }
  }

  body {
    overflow: hidden;
  }

  article {
    position: relative;
  }

  address,
  h1, h2, h3, h4, h5, h6,
  p,
  blockquote, pre,
  dl, ol, ul,
  hr,
  table,
  fieldset {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  dl, ol, ul {
    margin-left: 1.5rem;
  }

  input,
  button,
  a {
    outline-offset: 0.5rem;
  }

  a {
    color: ${tokens.color('link')};
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: ${tokens.color('link', 'hover')};
    }

    &:focus {
      color: ${tokens.color('link', 'focus')};
    }

    &:active,
    &.is-active {
      color: ${tokens.color('link', 'active')};
    }
  }

  .container {
    max-width: ${tokens.get('maxWidth.wide')};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .label {
    ${Label}
  }
`

export default Global
