import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens } from '@tokens'

export const Overlay = styled.div`
  background-color: ${tokens.color('white')};
  opacity: ${styledMap('open', {
    true: 0.6,
    default: 0,
  })};
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: ${styledMap('open', {
    true: 'all',
    default: 'none',
  })};
  transition: opacity ${tokens.get('transition.fancy')};
  z-index: ${tokens.z('overlay')};
`
