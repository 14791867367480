// TODO: Create alias for less verbosity

import DesignSystem from 'design-system-utils'
import ms from 'modularscale-js'
import palette from '@tokens/palette'
import { easing, duration } from '@tokens/easing'
import { generateMedia, pxToRem } from 'styled-media-query'
import { spacingValues } from '@tokens/spacing'
export { box, spacing } from '@tokens/spacing'

const modularscale = {
  base: [16],
  ratio: 1.333,
}

const fontFamily = {
  tisaPro: '"ff-tisa-web-pro", "DejaVu Serif", "Georgia", "Droid Serif", sans-serif',
  lato: '"Lato", "DejaVu Sans", "Verdana", "Roboto", sans-serif',
}


export const tokens = new DesignSystem(
  {
    type: {
      baseFontSize: '16px',

      sizes: {
        p: `${ms(0, modularscale)}px`,
        h6: `${ms(0, modularscale)}px`,
        h5: `${ms(1, modularscale)}px`,
        h4: `${ms(2, modularscale)}px`,
        h3: `${ms(3, modularscale)}px`,
        h2: `${ms(4, modularscale)}px`,
        h1: `${ms(5, modularscale)}px`,
      },

      fontFamily,
      fontFamilyBase: fontFamily.lato,
      fontFamilyHeading: fontFamily.tisaPro,

      lineHeight: {
        body: 1.75,
        heading: 1.15,
      },

      fontWeight: {
        normal: 300,
        regular: 400,
        bold: 700,
      },

      letterSpacing: {
        base: 'normal',
        small: '0.0375em',
        medium: '0.0625em',
        large: '0.125em',
      },
    },

    // TODO: Rethink this shit maybe
    spacing: {
      ...spacingValues,
    },

    colors: {
      ...palette,
    },

    zIndex: {
      under: -1,
      base: 0,
      content: 1,
      aboveContent: 2,
      header: 10,
      nav: 15,
      overlay: 20,
      flyout: 30,
      search: 40,
      cover: 100,
    },

    maxWidth: {
      narrow: '30rem',
      base: '40rem',
      wide: '81rem',
      vast: '90rem',
    },

    easing,
    duration,

    transition: {
      base: `${easing.base} ${duration.base}`,
      snappy: `${easing.in} ${duration.fast}`,
      fancy: `${easing.outCubic} ${duration.slow}`,
    },

    borderRadius: '0.2rem',

    component: {
      header: {
        height: '10rem',
      },
    },
  },
  {
    fontSizeUnit: 'rem',
  }
)

export const media = generateMedia(
  pxToRem({
    s: '600px',
    m: '900px',
    l: '1200px',
    xl: '1800px',
  })
)

export default tokens
