import styled from 'styled-components'
import { Label } from '@styles/Typography'
import { media, tokens, spacing } from '@tokens'

export const VideoBanner = styled.section`
  position: relative;
  overflow: hidden;
  height: 18.75rem;
  width: 100%;

  ${media.greaterThan('m')`
    height: 31.25rem;
    margin-top: ${spacing(10)};
  `};

  ${media.greaterThan('xl')`
    height: 40rem;
  `};

  .background-video {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: ${tokens.z('base')};
    min-height: 100%;
    transform: translateY(-50%);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%;
      z-index: ${tokens.z('content')};
      background: ${tokens.color('black')};
      opacity: 0.3;
    }

    .video-react-video {
      object-fit: cover;
    }

    .video-react-big-play-button {
      display: none;
    }
  }

  .video-react-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .video-play {
    ${Label}
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: ${tokens.z('aboveContent')};
    display: block;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    background: unset;
    border: unset;
    color: ${tokens.color('white')};

    .button__inner {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      white-space: nowrap;
    }
  }

  .play-icon {
    height: ${spacing(5)};
    width: ${spacing(5)};
    margin-right: ${spacing(1)};
    fill: ${tokens.color('white')};
  }
`

export const Flyout = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: ${spacing(3)};
  max-width: ${tokens.get('maxWidth.vast')};
  z-index: ${tokens.z('flyout')} !important;

  /* Prevents FOUC */
  html:not(.wf-active):not(.wf-inactive) & {
    opacity: 0;
  }

  .flyout-close {
    position: absolute;
    top: ${spacing(-1)};
    right: ${spacing(2)};
    height: ${spacing(4)};
    width: ${spacing(4)};
    z-index: ${tokens.z('flyout')};
    cursor: pointer;
    fill: ${tokens.color('black')};

    ${media.greaterThan('s')`
      top: ${spacing(4)};
      right: ${spacing(4)};
    `};

    ${media.greaterThan('m')`
      top: ${spacing(5)};
      right: ${spacing(5)};
    `};
  }
`
