import styled from 'styled-components'
import { Heading, Label } from '@styles/Typography'
import { media, tokens, spacing } from '@tokens'

export const Tiles = styled.section`
  position: relative;
  background: ${tokens.color('white')};

  ${media.greaterThan('1000px')`
    padding-top: ${spacing(5)};
  `};

  ${media.greaterThan('l')`
    padding-top: ${spacing(10)};
  `};

  .inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    ${media.greaterThan('m')`
      flex-flow: row;
    `};
  }

  .content,
  .image {
    flex: 1 1 100%;

    ${media.greaterThan('m')`
      flex-basis: 100%;
    `};
  }

  .content {
    padding: ${spacing(6)} ${spacing(5)} 0;

    ${media.greaterThan('s')`
      padding-top: ${spacing(10)};
    `}

    ${media.greaterThan('m')`
      padding-top: ${spacing(5)};
      order: unset;

      &::before {
        content: '';
        width: 100%;
        padding: 5%;
        display: block;
      }
    `};
  }

  .content-inner {
    max-width: ${tokens.get('maxWidth.narrow')};
    margin-left: auto;
    margin-right: auto;
  }

  h2 {
    ${Label}
    margin-bottom: ${spacing(2)};

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(3)};
    `};
  }

  h3 {
    ${Heading}
    margin-bottom: ${spacing(3)};
    font-size: 2.375rem;

    ${media.greaterThan('s')`
      margin-bottom: ${spacing(4)};
      font-size: 3rem;
    `};
  }

  .button {
    margin-top: ${spacing(3)};

    ${media.greaterThan('s')`
      margin-top: ${spacing(4)};
    `};
  }

  .image {
    position: relative;
    width: 50%;
    z-index: ${tokens.z('base')};
    background: ${tokens.brand('light')};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: ${tokens.z('base')};
      width: 100%;
      padding: 5%;
      display: block;
      background: ${tokens.color('white')};
    }

    .gatsby-image-wrapper {
      ${media.greaterThan('m')`
        width: 120%;
      `};
    }

    img {
      position: relative;
      z-index: ${tokens.z('content')};
      transform: translateX(14%);

      ${media.greaterThan('m')`
        transform: translateX(5%);
      `};
    }
  }
`
