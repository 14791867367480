const colours = {
  white: {
    base: '#fff',
    dark: '#f9f9fa',
  },
  black: {
    base: '#000',
  },
  grey: {
    base: '#424243',
    light: '#8c8987',
    lighter: '#e6e6e6',
    dark: '#303030',
    darker: '#181d25',
  },
}

const brand = {
  base: '#c65964',
  light: '#ffe4e3',
  lighter: '#7d63bd',
  dark: '#ad4e58',
  darker: '#94434b',
  bright: '#b967ff',
  pink: '#ff6ad5',
  blue: '#40485A',
  green: '#05ffa1',
}

export default {
  colorPalette: {
    ...colours,
    text: {
      base: brand.blue,
    },
    border: {
      base: colours.grey.light,
    },
    link: {
      base: brand.base,
      hover: brand.bright,
      focus: brand.pink,
      active: brand.pink,
    },
    gradient: {
      to: '#40485a',
      from: '#f2f4fc',
    },
  },
  brand: {
    ...brand,
  },
}
