import styled from 'styled-components'
import { Heading, Label } from '@styles/Typography'
import { media, tokens, spacing } from '@tokens'

export const ThankYouContent = styled.section`
  h1,
  p {
    max-width: 30rem;

    ${media.lessThan('s')`
      text-align: center;
    `};
  }

  h1 {
    ${Heading}
  }

  p {
    font-size: 1.25rem;
  }
`
