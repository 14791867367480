import styled, { css } from 'styled-components'
import { Label } from '@styles/Typography'
import styledMap from 'styled-map'
import { tokens, spacing } from '@tokens'

export const Button = styled.a`
  ${Label}
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding-left: ${spacing(3)};
  padding-right: ${spacing(3)};
  background-color: ${styledMap('styleType', {
    filled: tokens.brand('base'),
    default: 'unset',
  })};
  ${styledMap('styleType', {
    filled: css`border: 2px solid ${tokens.brand('base')}`,
    ghost: css`border: 2px solid ${tokens.color('white')}`,
    default: css`border-bottom: 2px solid ${tokens.color('text')}`,
  })};
  color: ${styledMap('styleType', {
    ghost: tokens.color('white'),
    filled: tokens.color('white'),
    default: tokens.color('text'),
  })};

  ${tokens.color('text')};
  text-decoration: none;
  transition: background-color ${tokens.get('transition.base')},
                  border-color ${tokens.get('transition.base')},
                         color ${tokens.get('transition.base')};

  &:focus {
    outline: ${tokens.color('link', 'focus')} 1px solid;
  }

  &:active,
  &:hover {
    background-color: ${tokens.brand('base')};
    border-color: ${tokens.brand('base')};
    color: ${tokens.color('white')};
  }

  &:last-child {
    margin-right: 0;
  }
`
