import styled from 'styled-components'
import { Label } from '@styles/Typography'
import { media, tokens, spacing } from '@tokens'

export const Countdown = styled.div`
  margin-left: 0;
  margin-right: auto;

  .label {
    display: block;
    margin-bottom: ${spacing(2)};
    line-height: 1.25;

    ${media.lessThan('s')`
      text-align: center;
    `};
  }

  .clock {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 20rem;
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('s')`
      max-width: 25rem;
      margin-left: 0;
    `};

    ${media.greaterThan('m')`
      max-width: 35rem;
    `};
  }

  .time-value {
    color: ${tokens.brand('base')};
    font-size: 3rem;
    font-weight: ${tokens.get('type.fontWeight.light')};
    line-height: 1;
    text-align: center;
    min-width: 3rem;

    &:first-child {
      margin-left: ${spacing(-1)};;
    }

    ${media.greaterThan('m')`
      font-size: 5rem;
      min-width: 6rem;

      &:first-child {
        margin-left: ${spacing(-4)};
      }
    `};
  }

  .time-label {
    display: block;
    ${Label}
  }
`