import styled from 'styled-components'
import { Label } from '@styles/Typography'
import { media, tokens, spacing } from '@tokens'

export const Footer = styled.section`
  background-color: ${tokens.color('grey')};

  .inner {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    padding: ${spacing(5)} ${spacing(3)};
    width: 100%;
    max-width: ${tokens.get('maxWidth.base')};
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('s')`
      justify-content: space-between;
    `};

    ${media.greaterThan('m')`
      flex-flow: row;
      max-width: 68.75rem;
    `};
  }

  .menu-list {
    margin: 0;
    padding: 0;

    ${media.lessThan('s')`
      flex: 1 1 100%;
      text-align: center;
      margin-bottom: ${spacing(4)};
    `}
  }

  .menu-list-item {
    list-style: none;
    line-height: 1;
  }

  .footer-heading,
  .menu-list-link {
    display: block;
    color: ${tokens.color('white')};
    font-weight: ${tokens.get('type.fontWeight.light')};
    line-height: 1;
    padding: ${spacing(1)} 0;
    text-transform: uppercase;
  }

  .menu-list-link {
    font-size: 0.875rem;

    &:hover {
      color: ${tokens.brand('base')};
    }
  }

  .footer-heading {
    ${Label}
    font-size: 0.875rem;
    padding-top: 0;
    color: ${tokens.color('white')};
    font-weight: ${tokens.get('type.fontWeight.bold')};
  }

  .logo-link {
    ${media.lessThan('m')`
      flex: 1 1 100%;
      margin-bottom: ${spacing(5)};
    `};

    svg {
      display: block;
      max-width: 8.75rem;
      width: 100%;

      ${media.lessThan('m')`
        margin-left: auto;
        margin-right: auto;
      `}
    }
  }

  .copyright {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    line-height: 1;
    padding: ${spacing(3)};
    border-top: 1px solid ${tokens.color('border')};
    color: ${tokens.color('border')};
    font-size: 0.75rem;
    letter-spacing: ${tokens.get('type.letterSpacing.large')};
    text-align: center;
    text-transform: uppercase;

    ${media.greaterThan('s')`
      flex-flow: row;
    `};

    a {
      display: inline-block;
      color: ${tokens.color('border')};
      padding: 0 ${spacing(0.5)};

      &:first-child {
        ${media.greaterThan('s')`
          padding: 0 ${spacing(1.5)};
          margin: 0 ${spacing(1.5)};
          border-left: 1px solid ${tokens.color('border')};
          border-right: 1px solid ${tokens.color('border')};
        `};

        ${media.lessThan('s')`
          margin: ${spacing(1.5)} 0;
          flex: 1 1 100%;
        `};
      }

      &:hover {
        color: ${tokens.brand('base')};
      }
    }
  }
`