import styled from 'styled-components'
import styledMap from 'styled-map'
import { tokens, spacing, media } from '@tokens'

export const Social = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: ${spacing(1)} 0 0;

  ${media.greaterThan('s')`
    justify-content: flex-start;
  `};

  li {
    list-style: none;
    margin-left: ${spacing(2)};

    &:first-child {
      margin-left: -0.25rem;
    }
  }

  a,
  svg {
    display: block;
  }

  svg {
    height: ${spacing(3)};
    width: ${spacing(3)};
    fill: ${styledMap('darkFill', {
      true: tokens.color('text'),
      default: tokens.color('white'),
    })};
    transition: fill 250ms linear;

    &:hover {
      fill: ${tokens.brand('base')};
    }
  }
`
