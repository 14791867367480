import styled from 'styled-components'
import styledMap from 'styled-map'
import { Label } from '@styles/Typography'
import { media, tokens, spacing, box } from '@tokens'

export const Hero = styled.div`
  ${box({
    spacing: 'horizontal',
  })};
  position: relative;
  height: 42.5rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: ${tokens.z('base')};
  overflow: hidden;
  background: linear-gradient(${tokens.color('gradient','to')}, ${tokens.color('gradient','from')} 70%);

  ${media.greaterThan('s')`
    height: 57rem;
  `};

  @media(min-width: 500px) and (max-width: 700px) and (orientation: landscape)  {
    height: 47rem;
  }

  @media(min-width: 700px) and (max-width: 900px) and (orientation: landscape)  {
    height: 49rem;
  }

  .container {
    position: relative;
    z-index: ${tokens.z('content')};
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .header {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: ${styledMap('hasCountdown', {
      true: 'center',
      default: 'space-between',
    })};
    height: 5rem;
    width: 100%;

    ${media.greaterThan('m')`
      justify-content: space-between;
      height: 6.875rem;
    `};
  }

  .inner {
    flex: 0 0 auto;
    margin-top: auto;
    margin-bottom: 0;
    width: 100%;
    padding-bottom: ${spacing(5)};

    ${media.greaterThan('s')`
      padding-left: ${spacing(5)};
      padding-right: ${spacing(5)};
      padding-bottom: ${spacing(10)};
    `};

    ${media.greaterThan('m')`
      padding-left: ${spacing(10)};
      padding-bottom: ${spacing(10)};
    `};
  }

  .logo {
    width: 10.25rem;
  }

  .label {
    ${Label}
    font-size: 0.875rem;

    ${media.greaterThan('m')`
      font-size: 1rem;
    `};
  }

  .rise-logo {
    display:block;
    margin-bottom: ${spacing(5)};
    max-width: 17.5rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: visible;

    ${media.greaterThan('s')`
      margin-left: 0;
      margin-bottom: ${spacing(8)};
    `};

    ${media.greaterThan('m')`
      width: 26.25rem;
    `};

    /* Prevents FOUC */
    html:not(.wf-active):not(.wf-inactive) & {
      opacity: 0;
    }
  }
`
