import React from "react"
import Helmet from 'react-helmet'
import { Normalize } from 'styled-normalize'
import GlobalStyle from '@styles/components/Global'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <>
        <Helmet />
        <Normalize />
        <GlobalStyle />
        {children}
      </>
    )
  }
}

export default Layout
