import styled from 'styled-components'
import { media, tokens, spacing, box } from '@tokens'

export const FloatingProducts = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  right: 0;
  z-index: ${tokens.z('base')};
  margin-left: auto;
  margin-right: auto;
  transform: translateX(-50%);
  width: 45rem;

  ${media.greaterThan('s')`
    width: 70rem;
  `}

  ${media.greaterThan('m')`
    width: 60rem;
  `}

  ${media.greaterThan('l')`
    width: ${tokens.get('maxWidth.wide')};
  `};

  .product-container {
    position: absolute;
    top: 6rem;
    left: 50%;
    height: 15rem;
    width: 100%;
    z-index: ${tokens.z('content')};
    transform: translateX(-50%);

    ${media.greaterThan('s')`
      height: 25rem;
      top: 7rem;
    `};

    ${media.greaterThan('m')`
      top: 10rem;
    `};
  }

  .product {
    position: absolute;

    .product-image {
      display: block;
      width: 100%;
    }
  }

  .product1 {
    top: 0.00%;
    left: 6.17%;
    width: 14.97%; /* 194 */
  }

  .product2 {
    top: 34.25%;
    left: 22.38%;
    width: 22.53%; /* 292 */
  }

  .product3 {
    top: 0.00%;
    left: 39.27%;
    width: 22.45%; /* 291 */
  }

  .product4 {
    top: 60.00%;
    left: 47.99%;
    width: 12.04%; /* 156 */
  }

  .product5 {
    top: 6.00%;
    left: 61.50%;
    width: 13.19%; /* 171 */
  }

  .product6 {
    top: 53.75%;
    left: 70.52%;
    width: 16.20%; /* 210 */
  }

  .product7 {
    top: 76.00%;
    left: 30.86%;
    width: 9.95%; /* 129 */

    ${media.greaterThan('m')`
      top: 15%;
      left: 80%;
    `};
  }

  .shadow-handler {
    position: absolute;
    top: 60%;
    left: 50%;
    width: calc(100% + ${spacing(18)});
    z-index: ${tokens.z('base')};
    transform: translateX(-50%);

    ${media.greaterThan('m')`
      top: 55%;
    `};

    img {
      width: 100%;
    }
  }
`
